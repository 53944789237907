<template lang="pug">
#create-user(v-esc-close="cancel" :class="panelClass")
  icon.close-panel(data="@icon/x.svg" @click="cancel" width="16" height="16" title="Close")

  h1 Create New User

  form#create-user-form(@submit.prevent="create")
    DynamicForm(
      :schema="form._fromConfig"
      :disabled="false"
      v-model="form"
      @status="saveStatus"
      ref="dynamicForm"
    )

    .password-instructions Passwords must:
      ul
        li Include at least 1 special character
        li Contain lower case (a-z), upper case (A-Z) &amp; numbers (0-9)
        li Be at least 8 characters in length

    CarrierList(:userControlledCarriers="form.carrierList" @carrierChange="handleCarrierChange")

    .panel-footer
      button.cancel-btn(type="button" @click="cancel") Cancel
      button.action-btn(type="submit" @click.prevent="create" :disabled="form.carrierList.length == 0") Create
</template>

<script>
import dynamicFormMixin from '@/mixins/dynamicFormMixin'
import PortalUser from '@/models/portalUser.js'

export default {
  mixins: [dynamicFormMixin],
  props: {},

  data() {
    return {
      form: { ...new PortalUser({}, ['admin']) },
      panelClass: '',
      passwordVisible: false,
    }
  },

  computed: {
    passwordFieldType,
  },

  watch: {},

  // created() {
  //   this.form = { ...new PortalUser() }
  // },

  mounted() {
    setTimeout(() => {
      this.panelClass = 'is-visible'
    }, 100)
  },

  methods: {
    handleCarrierChange(carriers) {
      this.form.carrierList = carriers
    },
    cancel,
    create,
    showPassword,
  },

  components: {
    CarrierList: require('@/components/CarrierList').default,
  },
}

/* Computed ---------------------------------------------------- */
function passwordFieldType() {
  return this.passwordVisible ? 'text' : 'password'
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.panelClass = ''
  setTimeout(() => {
    this.$panel.close('CreateUser')
  }, 250)
}

async function create() {
  this.$refs.dynamicForm.validate()

  if (this.status.invalid) {
    return
  }

  this.form.carrierId = this.$app.carrier.id

  const response = await this.$store.dispatch('portalUser/create', this.form)

  if (response.status != 200) {
    response.data?.message.forEach(errorMessage => {
      this.$notyf.error(errorMessage)
    })
  } else {
    this.$notyf.success('User created')
    this.cancel()
  }
}

function showPassword() {
  this.passwordVisible = !this.passwordVisible
}
</script>
